  import React from 'react'
  import Layout from '../../../../components/App/Layout'
  import Navbar from '../../../../components/App/NavbarRV'
  import Footer from '../../../../components/App/FooterRV'
  import KDPImagePage from '../../../../assets/images/pages/1-1-2-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-construction/'>Expertise construction</a></li><li><a href='/expertise-construction/expert-construction/'>L'expert construction</a></li><li>Objectifs</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Les objectifs d’une Expertise construction </h1>

<p>Les objectifs d’une expertise construction sont multiples :</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Objectifs" />
</div>


<ul><li>assister le client ou assuré dans ses démarches d’accompagnement,</li>
<li>définir les causes et origines de désordres,</li>
<li>préconiser des travaux de réparation adaptés</li>
<li>défendre votre intérêts lors d’une contre-expertise,</li>
<li>vous accompagner lors des phases judiciaires.</li></ul>

<p>L’Expert est votre référent technique et vous représente.</p>

<p>Il endosse un rôle de médiateur et vous représente lors d’échanges avec un artisan, une assurance afin de défendre vos intérêts. </p>
<h2>Les objectifs de l’Expertise de partie</h2>

<p>L’expertise dite de “partie” fait intervenir un Expert pour une mission ponctuelle sur une problématique donnée :</p>
<ul><li>assistance à réception,</li>
<li>expertise avant acquisition,</li>
<li>expertise technique construction</li>
<li>avis technique sur ouvrage, malfaçons</li>
<li>détermination causes et origines d’une pathologie,</li>
<li>préconisations et solutions réparatoires adaptées. </li></ul>

<p>À la suite de sa visite technique, l’expert vous remet un rapport. Un rapport d’expertise est opposable auprès des tribunaux. </p>

<h2>Les objectifs de l’expertise construction </h2>

<p>L’Expertise d’assuré diffère de l’expertise de partie puisqu'il s’agit ici d’accompagner un assuré pour la défense de ses intérêts, bien souvent dans un contexte de litige avec son assurance ou une entreprise.</p>

<p>Les objectifs de l’Expert construction d’assuré sont d’ici d’orienter l’assuré sur les solutions techniques réparatoires, dégager des responsabilités et d’informer sur les démarches permettant de résoudre à l’amiable un conflit.</p>

<p>Il entre en relation avec la partie adverse afin de trouver toutes solutions amiables avant le recours en procédure judiciaire.</p>

<h2>Les objectifs du cabinet RV EXPERTISES : vous accompagner de A à Z </h2>

<p>Les objectifs du cabinet RV EXPERTISES sont et resteront toujours les mêmes : vous accompagner afin de répondre au mieux à votre problématique.</p>

<p>De la prise en charge de votre dossier jusqu’à la remise du rapport, nous assurons une prestation d’accompagnement technique, une veille administrative et nous nous assurons que vos intérêts soient défendus à la juste valeur de votre préjudice. </p>


<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Demander un devis</a>
    </div>
</div>

                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-construction/expert-construction/objectifs/' className='active'>Objectifs</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details